.app-main-content {
  min-height: calc(100vh - 48px);
  padding-bottom: 10px;
  padding-top: 10px;
}
.app-footer {
  height: 48px;
  hr {
    margin-top: 0;
  }
}

.app-form-section {
  margin-bottom: 30px;
  hr {
    color: #028C8C;
    height: 5px;
    opacity: 1;
  }
  .title {
    color: #028C8C;
    font-size: 2em;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .info {
    font-size: 2.2em;
    margin-top: 1.2em;
    font-weight: 300;
    padding: 0 3em;
  }
  .image-container {
    display: flex;
    justify-content: center;
    margin-top: 2em;
    img {
      width: 180px;
    }
  }

  .mail-wrapper {
    margin-top: 10px;
    border-top: 1px dashed lightgrey;
    border-bottom: 1px dashed lightgrey;
    margin-bottom: 20px;

    & > div {
      padding: 0 15px;
    }
    p {
      text-align: justify;
      margin-bottom: 0;
    }
    .mail-content {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .mail-part {
      margin-bottom: 5px;
      label {
        margin-right: 5px;
        font-weight: 500;
        min-width: fit-content;
      }
      &.body {
        padding-top: 5px;
      }
    }
    .destination {
      display: flex;
      label {
      }
      a {
        margin-right: 10px;
        display: block;
      }
    }
  }
}

.theme-mafa {
  .app-form-section {
    hr {
      color: #91c73b;
    }
    .title {
      color: #91c73b;
    }
  }
  .btn-primary {
    background-color:  #91c73b;
    border-color:  #91c73b;
    &:hover {
      background-color: rgba(145, 199, 59, 0.78);
    }
  }
  a {
    color: #91c73b;
  }
}

.btn-primary {
  color: white !important;
  &.disabled, &:hover, &:active {
    color: white;
  }
}

@media (max-width: 1190px) {
  .app-form-section {
    .info {
      padding: 0 0.5em;
      font-size: 2em;
    }
  }
}